import React from "react";

import DefaultLayout from "../../layouts/default";

const Support = () => (
  <DefaultLayout title="Support">
    <div className="orange portrait">
      <header className="navWrapper">
        <nav className="mainNav singleNavBar">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li className="is-active">
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section>
          <div className="cContainer noSidesPadding is-background-color-orange is-color-white text-centered wsPaddingResp-bottom">
            <div className="wrapper minSidesPadding">
              <h1 className="largeStaticPadding-top mediumStaticPadding-bottom">
                Wir sind für Sie da!
              </h1>
              <div className="wrapperFlexLessSpace is-background-color-orange text-centered">
                <div className="has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/icons/icon-hotline.svg"
                    className="mediumStaticPadding-bottom cIcon"
                  />
                  <h5>Hotline</h5>
                  <p className="fliesstext">
                    Montag bis Freitag
                    <br />
                    8-12 und 13-17 Uhr
                  </p>
                  <br />
                  <a
                    className="buttonLink is-visible-mobile"
                    href="tel:+41712745133"
                  >
                    <button className="newButton hasWidth">
                      +41&nbsp;71&nbsp;274&nbsp;51&nbsp;33
                    </button>
                  </a>
                  <a
                    className="buttonLink is-visible-mobile"
                    href="mailto:hotline@wigasoft.ch"
                  >
                    <button className="newButton hasWidth">E-Mail</button>
                  </a>
                </div>

                <div className="has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/icons/icon-quicksupport.svg"
                    className="cIcon mediumStaticPadding-bottom"
                  />
                  <h5>TeamViewer</h5>
                  <p className="fliesstext">
                    QuickSupport-Modul ausführen und die generierte ID dem
                    WigaSoft-Support mitteilen.
                  </p>
                  <br />
                  <a
                    href="https://get.teamviewer.com/wigasoft"
                    target="_blank"
                    className="buttonLink is-visible-mobile"
                  >
                    <button className="newButton hasWidth">Download</button>
                  </a>
                </div>

                <div className="has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/icons/icon-fileexchange.svg"
                    className="mediumStaticPadding-bottom cIcon"
                  />
                  <h5>FileExchange</h5>
                  <p className="fliesstext">
                    Sie werden zum externen Login-Fenster umgeleitet.
                  </p>
                  <a
                    className="buttonLink is-visible-mobile"
                    target="_blank"
                    href="https://fileexchange.wigasoft.ch/"
                  >
                    <button className="newButton hasWidth">zum Login</button>
                  </a>
                </div>
              </div>

              <div className="wrapperFlexLessSpace is-background-color-orange text-centered is-visible-tablet">
                <div>
                  <a className="buttonLink" href="tel:+41712745133">
                    <button className="newButton hasWidth is-hidden-mobile-strict">
                      +41&nbsp;71&nbsp;274&nbsp;51&nbsp;33
                    </button>
                  </a>
                  <a className="buttonLink" href="mailto:hotline@wigasoft.ch">
                    <button className="newButton hasWidth is-hidden-mobile-strict">
                      E-Mail
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href="https://get.teamviewer.com/wigasoft"
                    target="_blank"
                    className="buttonLink"
                  >
                    <button className="newButton hasWidth is-hidden-mobile-strict">
                      Download
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    className="buttonLink"
                    target="_blank"
                    href="https://fileexchange.wigasoft.ch/"
                  >
                    <button className="newButton hasWidth is-hidden-mobile-strict">
                      zum Login
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

          <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

      <footer className="mainFooter">
        <div className="wrapper">
          <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
            <ul>
              <li>
                <a href="/">Startseite</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
            </ul>
          </div>
          <div className="footerNotAnotherFlexboxButAGrid linklist">
            <div className="flex-item1">
              <ul>
                <li>
                  <a href="/dokumentationsloesungen/heime/">
                    <strong>Heime</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/spitaeler/">
                    <strong>Spitäler</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    <strong>Rehabilitation</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item2">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    BESA
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    RAI-NH
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-B
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-H
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-r/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-R
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item3">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|now tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    WiCare|now LEP<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item4">
              <ul>
                <li>
                  <a href="/portrait/">
                    <strong>Über uns</strong>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/partner/">Partner</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                </li>
              </ul>
            </div>
            <div className="flex-item5">
              <ul>
                <li>
                  <a href="/support/">
                    <strong>Support</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="wrapper">
            <div className="column">
              © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
            </div>
            <div className="column has-padding-left">
              <ul>
                <li>
                  <a href="/datenschutz/">Datenschutzerklärung</a>
                </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
              </ul>
            </div>
            <div className="column align-right">
              <a href="/portrait/kontakt/" className="anfahrt">
                <img
                  id="location-pointer"
                  src="/assets/images/icons/icon-anfahrt.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </DefaultLayout>
);

export default Support;
